<template>
  <ion-page>
    <ion-header class="headerTop ion-no-border">
      <headertoolbar></headertoolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <div class="original_main_container">
        <ion-header collapse="condense"></ion-header>
        <ion-icon class="l_close_a" button @click="this.$router.push('/admin/dashboard')"
          src="assets/ICONOS/close.svg"></ion-icon>
        <p class="tabTitle">Intervención auditiva</p>
        <div class="new_main" :class="new_main_grabar + ' ' + new_main_inst">
          <button class="top_button ver_instrucciones"
            @click="this.new_main_grabar = 'new_main_instrucciones'"><ion-icon
              src="assets/ICONOS/cabina/info.svg"></ion-icon> <span>Instrucciones</span></button>
          <button class="top_button ir_a_grabar" @click="this.new_main_grabar = 'new_main_grabar'"><ion-icon
              src="assets/ICONOS/cabina/mic.svg"></ion-icon> <span>Ir a Grabar</span></button>
          <button class="top_button2 inst" @click="verInstrucciones"><ion-icon
              src="assets/ICONOS/cabina/info.svg"></ion-icon> <span>Instrucciones</span></button>
          <div class="nuevaVersion" :class="classgral + ' ' + (processing ? 'procesando_audio' : '')">

            <ion-grid>
              <ion-row>
                <ion-col class="col_g col_g1">
                  <button id="resetButton" ref="resetButton" @click="resetRecording()">
                    <ion-icon v-if="this.recording === true" src="assets/ICONOS/cabina/close.svg"></ion-icon>
                    <ion-icon v-else src="assets/ICONOS/cabina/reload.svg"></ion-icon>
                    <span>{{ this.recording === true ? 'Cancelar' : 'Reiniciar' }}</span></button>
                </ion-col>
                <ion-col class="col_g col_g2">
                  <button id="record" ref="startRecordingButton" @click="record()"><ion-icon
                      src="assets/ICONOS/cabina/mic.svg"></ion-icon><span>Grabar</span></button>
                  <div class="recording_animation"></div>
                </ion-col>
                <ion-col class="col_g col_g3">
                  <button id="stop" ref="stopButton" @click="stop()"><ion-icon
                      src="assets/ICONOS/cabina/stop.svg"></ion-icon><span>Detener</span></button>
                  <button id="continueButton" ref="continueButton" @click="getChannelList()"><ion-icon
                      src="assets/ICONOS/cabina/arrow-redo.svg"></ion-icon><span>Continuar</span></button>
                  <div v-if="channelsSelected.length > 0">
                    <button id="programar" @click="send()"><ion-icon
                        src="assets/ICONOS/cabina/checkmark-sharp.svg"></ion-icon><span>Programar</span></button>
                  </div>
                </ion-col>
              </ion-row>
            </ion-grid>

            <svg class="soundwave" xmlns="http://www.w3.org/2000/svg">
              <linearGradient id="gradient">
                <stop stop-color="#555" />
                <stop offset="1" stop-color="#222" />
              </linearGradient>
              <filter id="filter" y="-1" height="3">
                <feOffset dy="-45" result="o" />
                <feTurbulence baseFrequency=".04 .001" numOctaves="4" seed="1" result="a">
                  <animate attributeName="seed" calcMode="discrete" by="2" dur="0.5s" repeatCount="indefinite"
                    accumulate="sum" />
                </feTurbulence>
                <feTurbulence baseFrequency=".04 .001" numOctaves="4" seed="2">
                  <animate attributeName="seed" calcMode="discrete" by="2" dur="0.5s" repeatCount="indefinite"
                    accumulate="sum" begin="0.25s" />
                </feTurbulence>
                <feComposite operator="arithmetic" in="a">
                  <animate attributeName="k2" values="1;0;1" dur="0.5s" repeatCount="indefinite" />
                  <animate attributeName="k3" values="0;1;0" dur="0.5s" repeatCount="indefinite" />
                </feComposite>
                <feDisplacementMap in="o" scale="90" />
                <feComposite in="SourceGraphic" operator="in" />
              </filter>
              <rect id="half" x="-9%" y="50%" width="120%" height="75" fill="url(#gradient)" filter="url(#filter)" />
              <use href="#half" y="-101%" transform="rotate(180) scale(-1, 1)" />
            </svg>

            <div class="exito">

              <p>El audio se programó exitosamente</p>
              <div class="fireworks-wrapper">
                <div class="firework left wave yellow">
                  <svg viewBox="0 0 27 128">
                    <path fill="none" stroke="#FFF" stroke-linecap="round" stroke-width="8"
                      d="M2.27373675e-13,119.1 C2.27373675e-13,109.3 18,109.4 18.1,99.5 C18.2,89.6 0.1,89.6 0.2,79.7 C0.3,69.8 18.2,70 18.3,60.1 C18.4,50.2 0.3,50.2 0.4,40.3 C0.5,30.4 18.4,30.5 18.5,20.7 C18.6,10.9 0.5,10.8 0.6,0.9"
                      transform="translate(4 4)"></path>
                  </svg>
                </div>
                <div class="firework left wave yellow large">
                  <svg viewBox="56 94 26 264">
                    <path
                      d="M61.2 353.9c-.1-10.6 15.9-10.7 15.9-21.3 0-10.6-16.1-10.5-16.1-21.2s15.9-10.7 15.9-21.3c0-10.6-16.1-10.5-16.1-21.2s15.9-10.7 15.9-21.3c0-10.6-16.1-10.5-16.1-21.2s15.9-10.7 15.9-21.3c0-10.6-16.1-10.5-16.1-21.2s15.9-10.7 15.9-21.3c0-10.6-16.1-10.5-16.1-21.2s15.9-10.7 15.9-21.3c0-10.6-16.1-10.5-16.1-21.2"
                      stroke="#FFF" stroke-width="8" fill="none" stroke-linecap="round"></path>
                  </svg>
                </div>
                <div class="firework left wave cyan">
                  <svg viewBox="0 0 27 128">
                    <path fill="none" stroke="#FFF" stroke-linecap="round" stroke-width="8"
                      d="M2.27373675e-13,119.1 C2.27373675e-13,109.3 18,109.4 18.1,99.5 C18.2,89.6 0.1,89.6 0.2,79.7 C0.3,69.8 18.2,70 18.3,60.1 C18.4,50.2 0.3,50.2 0.4,40.3 C0.5,30.4 18.4,30.5 18.5,20.7 C18.6,10.9 0.5,10.8 0.6,0.9"
                      transform="translate(4 4)"></path>
                  </svg>
                </div>
                <div class="firework left figure hex">
                  <svg viewBox="285 -12 30 27">
                    <path
                      d="M291.5 11.8l-4.9-8.4c-.82-1.42-.82-3.18 0-4.6l4.9-8.4c.82-1.43 2.35-2.3 4-2.3h9.8c1.65 0 3.18.87 4 2.3l4.9 8.4c.82 1.42.82 3.18 0 4.6l-4.9 8.4c-.82 1.43-2.35 2.3-4 2.3h-9.8c-1.65 0-3.18-.87-4-2.3z"
                      fill="#FFF"></path>
                  </svg>
                </div>
                <div class="firework left figure square">
                  <svg viewBox="366 -10 25 25">
                    <rect width="25" height="24.98" rx="5.4" fill="#FFF" transform="translate(366 -10)"></rect>
                  </svg>
                </div>
                <div class="firework right wave white">
                  <svg viewBox="0 0 27 128">
                    <path fill="none" stroke="#FFF" stroke-linecap="round" stroke-width="8"
                      d="M2.27373675e-13,119.1 C2.27373675e-13,109.3 18,109.4 18.1,99.5 C18.2,89.6 0.1,89.6 0.2,79.7 C0.3,69.8 18.2,70 18.3,60.1 C18.4,50.2 0.3,50.2 0.4,40.3 C0.5,30.4 18.4,30.5 18.5,20.7 C18.6,10.9 0.5,10.8 0.6,0.9"
                      transform="translate(4 4)"></path>
                  </svg>
                </div>
                <div class="firework right wave cyan">
                  <svg viewBox="0 0 27 128">
                    <path fill="none" stroke="#FFF" stroke-linecap="round" stroke-width="8"
                      d="M2.27373675e-13,119.1 C2.27373675e-13,109.3 18,109.4 18.1,99.5 C18.2,89.6 0.1,89.6 0.2,79.7 C0.3,69.8 18.2,70 18.3,60.1 C18.4,50.2 0.3,50.2 0.4,40.3 C0.5,30.4 18.4,30.5 18.5,20.7 C18.6,10.9 0.5,10.8 0.6,0.9"
                      transform="translate(4 4)"></path>
                  </svg>
                </div>
                <div class="firework right wave yellow large">
                  <svg viewBox="56 94 26 264">
                    <path
                      d="M61.2 353.9c-.1-10.6 15.9-10.7 15.9-21.3 0-10.6-16.1-10.5-16.1-21.2s15.9-10.7 15.9-21.3c0-10.6-16.1-10.5-16.1-21.2s15.9-10.7 15.9-21.3c0-10.6-16.1-10.5-16.1-21.2s15.9-10.7 15.9-21.3c0-10.6-16.1-10.5-16.1-21.2s15.9-10.7 15.9-21.3c0-10.6-16.1-10.5-16.1-21.2s15.9-10.7 15.9-21.3c0-10.6-16.1-10.5-16.1-21.2"
                      stroke="#FFF" stroke-width="8" fill="none" stroke-linecap="round"></path>
                  </svg>
                </div>
                <div class="firework right figure triangle">
                  <svg viewBox="443 -9 26 24">
                    <path
                      d="M452.3-7c.74-1.22 2.07-1.96 3.5-1.96 1.43 0 2.76.74 3.5 1.96l4.4 7.5 4.4 7.5c.7 1.26.7 2.8-.02 4.04-.72 1.25-2.04 2.03-3.48 2.06h-17.5c-1.44-.03-2.76-.8-3.48-2.06-.7-1.25-.72-2.78-.02-4.04L448 .5l4.3-7.5z"
                      fill="#6cf0f2"></path>
                  </svg>
                </div>
                <div class="firework right figure plus">
                  <svg viewBox="212 -16 34 34">
                    <path d="M228.9-12v25.9M216 .9h25.9" stroke="#FFF" stroke-width="8" fill="none"
                      stroke-linecap="round"></path>
                  </svg>
                </div>
                <div class="firework line top">
                  <svg viewBox="289 515 290 298">
                    <path d="M291.1 810s27.7-55.57 142.94-170.9C549.3 523.77 577 517 577 517" stroke="#FFF"
                      stroke-width="5" stroke-dasharray="5 31" fill="none"></path>
                  </svg>
                </div>
                <div class="firework line left">
                  <svg viewBox="289 515 290 298">
                    <path d="M291.1 810s27.7-55.57 142.94-170.9C549.3 523.77 577 517 577 517" stroke="#FFF"
                      stroke-width="5" stroke-dasharray="5 31" fill="none"></path>
                  </svg>
                </div>
                <div class="firework line bottom">
                  <svg viewBox="289 515 290 298">
                    <path d="M291.1 810s27.7-55.57 142.94-170.9C549.3 523.77 577 517 577 517" stroke="#FFF"
                      stroke-width="5" stroke-dasharray="5 31" fill="none"></path>
                  </svg>
                </div>
              </div>

            </div>
            <div :class="processing ? 'procesando_audio' : 'no_procesando'">
              <div class="loader_mini_mini"></div>Procesando audio...
            </div>
            <div id="soundClips" ref="soundClips">
              <article ref="clipContainer" class="clip" style="padding: 20px;">
                <audio controls ref="audioTag" type="audio/wav"></audio>
                <!-- <p ref="clipLabel">{{clipName}}</p> -->
              </article>
            </div>
            
            <div class="lista_canales">
              <!-- Tabla de lista de canales con nombre e imagen una vez haya datos en variable channelList, en la cual se pueda realizar un multiselect que guarde en un array el id de los seleccionados y que al desseleccionarlos los remueva de la lista -->
              <ion-list v-if="channelList != null">
                <ion-list-header>
                  <ion-label>
                    <h4>Lista de canales</h4>
                    <small>Selecciona los canales donde quieres escuchar tu audio.</small>
                  </ion-label>
                </ion-list-header>
                <ion-item @click="selectAll()">
                  <ion-label>Seleccionar todo</ion-label>
                  <ion-checkbox :checked="channelsSelected.length === channelList.length"></ion-checkbox>
                </ion-item>
                <ion-item v-for="channel in channelList" :key="channel.id">
                  
                  <ion-card class="selectCardItem">
                    <ion-card-header class="cardHeader">
                      <ion-card-title class="cardTitle">
                        <ion-item class="cardChanel" @click="selectChannel(channel.id)" style="width: 100%;">
                          <ion-avatar class="cardAvatar" slot="start">
                            <img class="cardAvatarImage" :src="'https://pub-3b7d9c13a5754ebeaa0345eb87aa22c1.r2.dev/images/' + channel.mobileimage" />
                          </ion-avatar>
                          <ion-label class="cardChanelName">{{ channel.name }}</ion-label>
                          <ion-checkbox class="cardChanelCheckbox" slot="end" :checked="isCheckedChannelsSelected(channel.id)"></ion-checkbox>
                        </ion-item>
                      </ion-card-title>
                    </ion-card-header>

                    <ion-card-content class="cardContent" v-show="channelsSelected.includes(channel.id)">
                        <ion-item class="cardVariant statusBarItem"  >
                          <ion-label class="cardVariant" v-if="channelsSelected.includes(channel.id) && !multiChannelsSelected[channel.id]">
                            Reproduciendo en Base
                          </ion-label>
                          <ion-label class="cardVariant" v-else>
                            Reproduciendo en {{ multiChannelsSelected[channel.id] ? multiChannelsSelected[channel.id].length : 0 }} Sucursal<span v-if="multiChannelsSelected[channel.id] && multiChannelsSelected[channel.id].length >= 2">es</span>
                          </ion-label>
                        </ion-item>
                        <ion-item class="cardVariant" @click="selectAllVariant(channel.id)" >
                          <ion-label class="cardVariant">
                            Seleccionar todo
                          </ion-label>
                          <ion-checkbox class="cardVariantCheckbox" slot="end" :checked="isCheckedBaseSelected(channel.id)" @click="handleCheckboxChange($event, channel.id)" ></ion-checkbox>
                        </ion-item>
                        <ion-item class="cardVariant" v-for="variant in channel.variants" :key="variant.id" @click="selectVariant(channel.id, variant.id)" style="width: 100%;">
                          <ion-label class="cardVariant">{{ variant.name }}</ion-label>
                          <ion-checkbox class="cardVariantCheckbox" slot="end" :checked="isCheckedMultiChannelsSelected(channel.id, variant.id)"></ion-checkbox>
                        </ion-item>
                    </ion-card-content>
                  </ion-card><!-- <span v-if="multiChannelsSelected[channel.id] && multiChannelsSelected[channel.id].size >= 2">es</span> -->

                </ion-item>
                
              </ion-list>
            </div>
            <!-- Si hay uno o más channels en la lista channelsSelected, mostrar el botón para programar el audio ejecutando la función send() -->

          </div>
          <div id="instrucciones">
            <h3>Instrucciones</h3>
            <p>1. Presiona el botón de <span><ion-icon src="assets/ICONOS/cabina/mic.svg"></ion-icon>Grabar</span>,
              si estas en un navegador, éste puede pedirte permisos para usar tu micrófono, una vez otorgados, la
              grabación iniciará,
              habla directo a tu micrófono y comunica el mensaje que quieres transmitir.</p>
            <p>2. Si algo salió mal, puedes cancelar la grabación presionando el boton de <span><ion-icon
                  src="assets/ICONOS/cabina/close.svg"></ion-icon>Cancelar</span>.
              Si crees que salió bien, presiona el botón de
              <span><ion-icon src="assets/ICONOS/cabina/stop.svg"></ion-icon>Detener</span>,
              a continuación se mostrará un reproductor donde podrás escucharlo.
            </p>
            <p>3. Si el audio no salió como lo planeaste presiona el botón de
              <span><ion-icon src="assets/ICONOS/cabina/reload.svg"></ion-icon>Reiniciar</span>
              para comenzar desde el principio.
            </p>
            <p>4. Cuando estés satisfecho con tu grabación presiona el botón de
              <span><ion-icon src="assets/ICONOS/cabina/arrow-redo.svg"></ion-icon>Continuar</span>.
            </p>
            <p>5. Se listarán los canales que tengas disponibles, selecciona solo los que quieras que reproduzcan tu
              mensaje. Al terminar presiona el botón de <span><ion-icon
                  src="assets/ICONOS/cabina/checkmark-sharp.svg"></ion-icon>Programar</span>,
              tu audio sera enviado directo a los canales seleccionados y se escuchará al terminar el audio actual.</p>
            <p>
              Programación:<br>
              - Base: Al seleccionar el canal en la lista, sin seleccionar ninguna sucursal... esto programará el audio en Base (Todas las sucursales conectadas sin importar si están identificados o no).<br>
              - Por Sucursal: Al seleccionar por lo menos una sucursal, la base es deshabilitada por defecto; ya que está especificando en donde quiere que suene el audio.<br>
              - En todas las Sucursales: Si selecciona todas las sucursales, al igual que la opción anterior... el audio se reproducirá en todas las sucursales, pero no sonará si la sucursal no está identificada.
            </p>
          </div>
        </div>
      </div>
    </ion-content>
    <!-- <ion-button @click="signOut" class="logoutButtonCabina"><ion-icon src="assets/ICONOS/cabina/log-out-outline.svg"></ion-icon>Cerrar sesión</ion-button> -->
  </ion-page>
</template>


<script>
/* eslint-disable */
import { alertController, IonPage, IonHeader, IonToolbar, IonContent, IonRefresher, IonRefresherContent } from '@ionic/vue';
import axios from 'axios';
import { defineComponent } from 'vue';
import { Storage } from '@capacitor/storage';
import headertoolbar from '../header_toolbar.vue';
import { Capacitor } from '@capacitor/core';
import 'url-polyfill';
import 'blob-polyfill';

export default ({
  name: 'Reproductor',
  components: { IonHeader, IonToolbar, IonContent, IonPage, headertoolbar, IonRefresher, IonRefresherContent },
  data() {
    return {
      urlApi: 'https://panel.vybroo.net/',
      recording: false,
      recordedTime: 0,
      chunks: [],
      audioUrl: '',
      processing: false,
      mediaRecorder: null,
      clipName: "Sin grabación",
      clientData: null,
      recordButton: null,
      stopButton: null,
      continueButton: null,
      resetButton: null,
      soundClips: null,
      stream: null,
      streamName: null,
      clipContainer: null,
      clipLabel: null,
      audio: null,
      audioBlob: null,
      audioURL: null,
      token: null,
      channelList: null,
      classgral: '',
      channelsSelected: [],
      multiChannelsSelected: [],
      new_main_grabar: 'new_main_grabar',
      new_main_inst: 'no_inst',
      audioFile: null,
      audioM4a: null,
      clientData: {},

    }
  },
  methods: {
    handleCheckboxChange(event, channel) {
      setTimeout(() => {
        if (event.target.checked == false) {
          this.multiChannelsSelected = this.multiChannelsSelected.filter(id => id !== channel.id);
          if(this.multiChannelsSelected[channel]){
            if(this.multiChannelsSelected[channel].length == 0){
              delete this.multiChannelsSelected[channel];
            }
          }
        }
      }, 300);
    },
    isCheckedChannelsSelected(channel) {
      if (!this.channelsSelected) {
        return false;
      }else{
        if (this.channelsSelected.includes(channel)) {
          return true;
        }else{
          return false;
        }
      }
    },
    isCheckedBaseSelected(channel) { 
      try {
        if (!this.multiChannelsSelected[channel]) {
          return false;
        }else{ 
          if(this.multiChannelsSelected[channel].length == this.channelList.find(item => item.id === channel).variants.length){
            console.log("multiChannelsSelected.variants.length:" + this.multiChannelsSelected[channel].length);
            console.log("channelList.variants.length:" + this.channelList.find(item => item.id === channel).variants.length);
            console.log("this.channelList[channel].variants:", this.channelList.find(item => item.id === channel).variants);
            return true;
          }else{
            return false;
          }
        }
      } catch (error) {
        console.log("Error al verificar si esta seleccionado el canal base: "+error);
      }
    },
    isCheckedMultiChannelsSelected(channel, variable) {
      if (!this.multiChannelsSelected[channel]) {
        return false;
      }else{ 
        if(this.multiChannelsSelected[channel].includes(variable)){
          return true;
        }else{
          return false;
        }
      }
    },
    selectAll() {
      if (this.channelsSelected.length === this.channelList.length) {
        this.channelsSelected = [];
      } else {
        this.channelsSelected = [];
        this.channelList.forEach(channel => {
          this.channelsSelected.push(channel.id);
        });
      }
    },
    selectChannel(channel) {
      if (this.channelsSelected.includes(channel)) {
        this.channelsSelected = this.channelsSelected.filter(item => item !== channel);
        return;
      }
      this.channelsSelected.push(channel); 
    },
    selectVariant(channel,variant) { 
      console.log("Canal seleccionado: " + channel+ " Variante seleccionada: " + variant);
      try{
        if (!this.multiChannelsSelected[channel]) {
          this.multiChannelsSelected[channel] = [];
        }

        if (this.multiChannelsSelected[channel].includes(variant)) {
          this.multiChannelsSelected[channel] = this.multiChannelsSelected[channel].filter(item => item !== variant);
          if(this.multiChannelsSelected[channel].length == 0){
            delete this.multiChannelsSelected[channel];
          }
        }else{
          this.multiChannelsSelected[channel].push(variant);
          if (!this.channelsSelected.includes(channel)) {
            this.channelsSelected.push(channel);
          }
        }
        

        console.log('multiChannelsSelected',this.multiChannelsSelected); 
     }catch(error){
      console.log("Error al seleccionar variante: "+error);
    }
    },
    selectAllVariant(channel) {
      if (!this.multiChannelsSelected[channel]) {
        this.multiChannelsSelected[channel] = [];
      }
      if (this.multiChannelsSelected[channel].length === this.channelList.find(item => item.id === channel).variants.length) {
        this.multiChannelsSelected[channel] = [];
      } else {
        this.multiChannelsSelected[channel] = [];
        this.channelList.find(item => item.id === channel).variants.forEach(variant => {
          this.multiChannelsSelected[channel].push(variant.id);
        });
      }
    },
    verInstrucciones() {
      if (this.new_main_inst === "no_inst") {
        this.new_main_inst = "si_inst";
      } else {
        this.new_main_inst = "no_inst";
      }
    },
    async record() {
      try {
        const isTokenValid = await this.getStorageAuthToken();
        console.log("Resultado de la validacion del token: " + isTokenValid);


        //ejecutar funcion de validacion de token y si es valido, ejecutar la grabacion, no ejecutar nada si el token es invalido, pero esperar a que el token sea valido
        if (isTokenValid) {

          this.recording = true;
          this.chunks = [];

          // si es navegador
          if (navigator.mediaDevices) {
            navigator.mediaDevices.getUserMedia({ audio: true })
              .then(stream => {

                this.mediaRecorder = new MediaRecorder(stream);

                this.mediaRecorder.addEventListener("dataavailable", event => {
                  if (event.data.size > 0) {
                    this.chunks.push(event.data);
                  }
                });

                this.mediaRecorder.addEventListener("stop", () => {
                  try {
                    this.processing = true;
                    this.audioBlob = new Blob(this.chunks, { type: "audio/m4a" });
                    this.clipName = `Clip ${new Date().toISOString()}.m4a`;
                    this.$refs.audioTag.src = URL.createObjectURL(this.audioBlob);
                    const audioFile = new File([this.audioBlob], 'audio.m4a', { type: "audio/mp4" });
                    this.$refs.audioTag.src = URL.createObjectURL(audioFile);
                    this.$refs.audioTag.controls = true;
                    console.log(this.$refs.audioTag.src);
                  } catch (error) {
                    console.error("Error procesando Audio:" + error);
                  }
                  setTimeout(() => {
                    this.processing = false;
                  }, 1500);
                });

                this.mediaRecorder.start();

                // ocultar boton de grabar y mostrar botones de stop y reset
                this.classgral = "recording";
                //this.$refs.startRecordingButton.style.display = "none";
                this.$refs.stopButton.style.display = "inline-block";
                this.$refs.resetButton.style.display = "inline-block";
                return;
              })
              .catch(error => {
                console.error("Error al acceder al micrófono:", error);
                return;
              });
          }
          //si es app
          else if (navigator.getUserMedia) {
            navigator.getUserMedia({ audio: true }, stream => {
              this.mediaRecorder = new MediaRecorder(stream);

              this.mediaRecorder.addEventListener("dataavailable", event => {
                if (event.data.size > 0) {
                  this.chunks.push(event.data);
                }
              });

              this.mediaRecorder.addEventListener("stop", () => {
                try {
                  this.processing = true;
                  this.audioBlob = new Blob(this.chunks, { type: "audio/m4a" });
                  this.clipName = `Clip ${new Date().toISOString()}.m4a`;
                  this.$refs.audioTag.src = URL.createObjectURL(this.audioBlob);
                  const audioFile = new File([this.audioBlob], 'audio.m4a', { type: "audio/mp4" });
                  this.$refs.audioTag.src = URL.createObjectURL(audioFile);
                  this.$refs.audioTag.controls = true;
                  console.log(this.$refs.audioTag.src);
                } catch (error) {
                  console.error("Error procesando Audio:" + error);
                }
                setTimeout(() => {
                  this.processing = false;
                }, 1500);
              });

              this.mediaRecorder.start();

              // ocultar boton de grabar y mostrar botones de stop y reset
              this.classgral = "recording";
              //this.$refs.startRecordingButton.style.display = "none";
              this.$refs.stopButton.style.display = "inline-block";
              this.$refs.resetButton.style.display = "inline-block";
              return;
            }, error => {
              console.error("Error al acceder al micrófono:", error);
              return;
            });
          }
          // si es iOS
          else if (navigator.webkitGetUserMedia) {
            navigator.webkitGetUserMedia({ audio: true }, stream => {
              this.mediaRecorder = new MediaRecorder(stream);

              this.mediaRecorder.addEventListener("dataavailable", event => {
                if (event.data.size > 0) {
                  this.chunks.push(event.data);
                }
              });

              this.mediaRecorder.addEventListener("stop", () => {
                try {
                  this.processing = true;
                  this.audioBlob = new Blob(this.chunks, { type: "audio/m4a" });
                  this.clipName = `Clip ${new Date().toISOString()}.m4a`;
                  this.$refs.audioTag.src = URL.createObjectURL(this.audioBlob);
                  const audioFile = new File([this.audioBlob], 'audio.m4a', { type: "audio/mp4" });
                  this.$refs.audioTag.src = URL.createObjectURL(audioFile);
                  this.$refs.audioTag.controls = true;
                  console.log(this.$refs.audioTag.src);
                } catch (error) {
                  console.error("Error procesando Audio:" + error);
                }
                setTimeout(() => {
                  this.processing = false;
                }, 1500);
              });

              this.mediaRecorder.start();

              // ocultar boton de grabar y mostrar botones de stop y reset
              this.classgral = "recording";
              //this.$refs.startRecordingButton.style.display = "none";
              this.$refs.stopButton.style.display = "inline-block";
              this.$refs.resetButton.style.display = "inline-block";
              return;
            }, error => {
              console.error("Error al acceder al micrófono:", error);
              return;
            });
          }
          else {
            console.error("No se puede acceder al micrófono");
            return;
          }
        } else {
          console.log("Token inválido");
        }

      } catch (error) {
        console.log("Error al iniciar la grabación: " + error);
      }
    },
    stop() {
      try {
        this.recording = false;
        this.mediaRecorder.stop();
        this.$refs.audioTag.controls = true;

        //liberar el stream de audio
        if (this.mediaRecorder.stream) {
          this.mediaRecorder.stream.getTracks().forEach(track => track.stop());
        }

        //ocultar boton de stop y mostrar botones de reset y continue
        this.classgral = "stop";
        this.$refs.stopButton.style.display = "none";
        this.$refs.resetButton.style.display = "inline-block";
        this.$refs.continueButton.style.display = "inline-block";
      } catch (error) {
        console.log("Error al detener la grabación: " + error);
      }
    },
    resetRecording() {
      try {
        if (this.recording == true) { this.stop(); }
        this.clipName = "Sin grabación";
        this.$refs.audioTag.removeAttribute("src");
        this.$refs.audioTag.load();
        this.chunks = [];
        this.mediaRecorder = null;
        this.$refs.startRecordingButton.disabled = false;
        this.channelsSelected = [];
        this.channelList = null;

        // ocultar botones de stop, continue y reset y mostrar botón de grabar
        this.$refs.stopButton.style.display = "none";
        this.$refs.continueButton.style.display = "none";
        this.$refs.resetButton.style.display = "none";
        this.$refs.startRecordingButton.style.display = "inline-block";
        if (this.classgral != "programmed") {
          this.classgral = "";
        }
      } catch (error) {
        console.log("Error al resetear la grabación: " + error);
      }

    },

    async getChannelList() {
      if (!this.$refs.audioTag.src) {
        if (this.processing != true) {
          this.resetRecording();
        }
        return;
      }
      const data = { token: this.token }
      console.log("Enviando este token:" + data.token);
      await axios.post(this.urlApi + "cabina/channel_list", data)
        .then(r => {
          if (!r.data.channels) {

            const headerAlert = "Error";
            const messageAlert = "No se pudo obtener la lista de canales";
            const buttonTextConfirm = "Aceptar";
            const buttonTextCancel = "Cancelar";
            const functionConfirm = this.resetRecording;
            const functionCancel = this.resetRecording;
            this.presentAlertConfirm(headerAlert, messageAlert, buttonTextConfirm, buttonTextCancel, functionConfirm, functionCancel)
            return this.resetRecording();
          } else {
            console.log("Lista de canales:");
            console.log(r.data);
            this.channelList = r.data.channels;
            this.$refs.continueButton.style.display = "none";
          }
        })
        .catch(error => {
          console.log(error);
        });
    },

    send() {
      const headerAlert = "Aviso";
      const messageAlert = '¿Estás seguro que deseas enviar el audio a ' + (this.channelsSelected.length > 1 ? this.channelsSelected.length + ' canales?' : this.channelsSelected.length + ' canal?');
      const buttonTextConfirm = "Si";
      const buttonTextCancel = "Cancelar";
      const functionConfirm = () => {
        this.realSend();
      }
      const functionCancel = () => {
        console.log('Cancelar pulsado');
        return;
      }
      this.presentAlertConfirm(headerAlert, messageAlert, buttonTextConfirm, buttonTextCancel, functionConfirm, functionCancel)
    },
    
    async realSend() {
      //enviar archivo de audio m4a, lista de channels y token
      if (this.audioBlob == null) {
        alert('No has grabado ningún audio');
        return;
      }

      if (this.channelsSelected.length == 0) {
        alert('No has seleccionado ningún canal');
        return;
      }
      const audioFile = new File([this.audioBlob], 'audio.m4a', { type: "audio/mp4" });

      //imprimir Mimetipe del archivo
      console.log("Mime type del archivo: " + this.audioBlob.type);
      
      const data = new FormData();
          data.append('audio', audioFile, 'audio.mp4');
          data.append('channels[]', this.channelsSelected);

      var channelVariants = [];
      var channelVariantsText = '';
      for (var i = 0; i < this.channelsSelected.length; i++) {
        channelVariantsText += this.channelsSelected[i] + ':';
      var variants = [];
        if (this.multiChannelsSelected[this.channelsSelected[i]]) {//SI ENCONTRO VARIANTES
          this.multiChannelsSelected[this.channelsSelected[i]].forEach(variant => {
            variants.push(variant);
            channelVariantsText += variant + ',';
          });
        }//SI ENCONTRO VARIANTES
        else {
          variants.push('base');
          channelVariantsText += '0,';
        }
        channelVariantsText = channelVariantsText.slice(0, -1);
        channelVariantsText += '|';
        channelVariants[this.channelsSelected[i]] = variants;
      }// POR CADA CANAL 
      channelVariantsText = channelVariantsText.slice(0, -1);

      console.log("channelVariants: ", channelVariants);
      console.log("channelVariants: ", channelVariantsText);

          data.append('variants[]', channelVariantsText);
          data.append('token', this.token);
          //imprimir channels, token y audio
          console.log("Channels: " + this.channelsSelected);
          console.log("Token: " + this.token); 
          console.log("Audio: " + audioFile);
          console.log("Longitud de channelsSelected: " + this.channelsSelected.length);

            axios.post(this.urlApi + "cabina/programar_audio", data)
              .then(r => {
                console.log(r.data);
                if (r.data.success === true) {
                    console.log("Audio enviado correctamente");
                    this.classgral = "programmed";
                    setTimeout(() => {
                      this.classgral = "";
                    }, 5000);
                    this.resetRecording();
                } else {
                  if (r.data.error == true) {
                    console.log("Error al enviar el audio");
                  }
                }
              }).catch(error => {
                console.log(error);
              });
    },

    async presentAlertConfirm(headerAlert, messageAlert, buttonTextConfirm, buttonTextCancel, functionConfirm, functionCancel) {
      const alert = await alertController
        .create({
          header: headerAlert,
          message: messageAlert,
          buttons: [
            {
              text: buttonTextConfirm,
              handler: () => {
                functionConfirm();
              }
            },
            {
              text: buttonTextCancel,
              handler: () => {
                functionCancel();
              }
            }
          ]
        });
      return alert.present();
    },
    async signOut() {
      const alert = await alertController
        .create({
          header: "Aviso",
          message: "¿Estás seguro que deseas cerrar la sesión?",
          buttons: [
            {
              text: 'Si',
              handler: () => {
                Storage.remove({ key: 'authToken' });
                Storage.remove({ key: 'cabina_admin_user' });
                Storage.remove({ key: 'cabina_admin_channel_list' });
                Storage.remove({ key: 'cabina_admin_selectedChannel' });
                this.$router.replace("/admin/LoginAdmin");
                this.emitter.emit('cabina_admin_user', '',);
                this.emitter.emit('cabina_admin_channel_list', '',);
              }
            },
            {
              text: 'Cancelar',
              handler: () => {
                console.log('Cancelar pulsado');
              }
            },
          ]
        });
      return alert.present();

    },
    onError(e) {
      console.error('Error reproduciendo audio');
      this.resetRecording();
    },
    async getStorageClientData(keyName) {
      try {
        const ret = await Storage.get({ key: keyName });
        this.clientData = this.isJson(ret.value);
        // console.log(this.clientData);
      } catch (error) {
        console.log("Error en función getStorageClientData de cabinaAdmin: " + error.message);
      }
    },
    isJson(str) {
      try {
        const parsedStr = JSON.parse(str);
        return parsedStr;
      } catch (e) {
        return str;
      }
    },
    async getStorageAuthToken() {
      try {
        console.log("Obteniendo token del storage...")
        const ret = await Storage.get({ key: 'authToken' });
        if (ret.value) {
          const tokenObj = JSON.parse(ret.value);
          this.token = tokenObj.value;
          console.log("Token obtenido: " + this.token);
          // console.log("Token obtenido:" + this.token)
          return this.validateToken(this.token);
        } else {
          console.log("Token no encontrado");
          this.presentAlertTokenInvalid();
          return false;
        }
      } catch (error) {
        console.log("Error en función getStorageAuthToken de cabinaAdmin: " + error.message);
      }
    },
    async presentAlertTokenInvalid() {
      const alert = await alertController
        .create({
          header: "Aviso",
          message: "Tu sesión ha expirado, por favor inicia sesión nuevamente",
          buttons: [
            {
              text: 'Aceptar',
              handler: () => {
                this.$router.replace("/admin/LoginAdmin");
              }
            },
          ]
        });
      return alert.present();
    },
    async validateToken(token) {
      try {
        console.log("revisando validez de token: " + token + " ...");
        if (token == null || token == undefined || token == "") {
          this.presentAlertTokenInvalid();
          return false;
        }
        if (token != null && token != undefined && token != "") {
          let url = "api/user_auth";
          if (Capacitor.getPlatform() == 'ios') url = "https://panel.vybroo.net/user_auth";
          let response = await axios.post(url, { token: token })
            .then(r => {
              console.log("r.data:");
              console.log(r.data);
              return r;
            }).catch(error => {
              console.log("Error en función validateToken de cabinaAdmin: " + error.message);
              return error;
            });
          console.log("response.data:");
          console.log(response.data);
          console.log("response.data.success: " + response.data.success);
          console.log("response.data.error: " + response.data.error);
          if (response.data.error === true) {
            console.log("Error al validar token:");
            console.log(response.data);
            this.presentAlertTokenInvalid();
            return false;
          }
          if (response.data.success === true) {
            console.log("Token válido");
            return true;
          } else {
            console.log("Token inválido");
            console.log("2response.data.success: " + response.data.success);
            console.log("2response.data.error: " + response.data.error);
            this.presentAlertTokenInvalid();
            return false;
          }
        }
      } catch (error) {
        console.log("Error en función validateToken de cabinaAdmin: " + error.message);
        return false;
      }
    },
    async getFullClientData() {
      try {
        const ret = await Storage.get({ key: "clientData" });
        if (ret.value) {
          this.clientData = JSON.parse(ret.value);
          console.log("Recuperado de storage, key: clientData - " + this.clientData);

          if (this.clientData.sonara) {
            this.emitter.emit("sonara", true);
          } else {
            this.emitter.emit("sonara", false);
          }
        } else {
          console.log("No se pudo recuperar de storage, key: clientData - " + this.clientData);
        }
      } catch (e) {
        this.presentAlertConfirm("Ha ocurrido un error en la función getFullClientData", e);
      }
    },
  },
  mounted: function () {
    try {
      if (Capacitor.getPlatform() == 'ios') {
        this.urlApi = "https://panel.vybroo.net/";
      } else {
        this.urlApi = "/api/";
      }
      //asignar botones a las refs
      this.recordButton = this.$refs.startRecordingButton;
      this.stopButton = this.$refs.stopButton;
      this.continueButton = this.$refs.continueButton;
      this.resetButton = this.$refs.resetButton;
      this.soundClips = this.$refs.soundClips;
      this.clipContainer = this.$refs.clipContainer;
      this.clipLabel = this.$refs.clipLabel;
      this.audio = this.$refs.audioTag;

      // Estado inicial de los botones
      this.recordButton.style.display = "inline-block";
      this.stopButton.style.display = "none";
      this.continueButton.style.display = "none";
      this.resetButton.style.display = "none";

      //get auth token de storage y asignarlo a la variable token
      this.getStorageAuthToken();


      //obtener datos del cliente
      this.getStorageClientData("clientData");
      this.getFullClientData();
      if (navigator.mediaDevices) {
        // this.startRecording3();
      }

      this.resetRecording();

    } catch (error) {
      console.log("Error en función mounted de cabinaAdmin: " + error.message);
    }
  },

})
</script>
<style lang="css">
ion-icon {
  color: white !important;
}
.statusBarItem {
  --background: transparent !important;
    text-align: right;
    padding: 0px;
    margin: 0px;
    line-height: 14px;
    font-size: 14px;
    color: #a4a4a4;
}
.selectCardItem {
  padding: 0 !important;
    margin: 0 !important;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    border: 0px;
}
.selectCardItem .cardHeader {
  padding: 0 !important;
    margin: 0 !important;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    border: 0px;
}
.selectCardItem .cardTitle {
}
.selectCardItem .cardChanel {
}
.selectCardItem .cardAvatar {
}
.selectCardItem .cardAvatarImage {
}
.selectCardItem .cardChanelName {
}
.selectCardItem .cardChanelCheckbox {
}
.selectCardItem .cardContent { 
    padding: 0;
    margin: 0;
    border: 0;
}
.selectCardItem .cardVariant {
  --background: #161616;
}
.selectCardItem .cardVariant:hover {
  --background: #040404;
}
.selectCardItem .cardVariantCheckbox {
}



.loader_mini_mini {
  margin: 0px;
  position: relative;
  display: inline-block;
  margin-right: 2px;
  margin-bottom: -1px;
}

.procesando_audio {
  display: block;
}

.no_procesando {
  display: none;
}


#record {
  background: red;
  cursor: pointer;
  font-size: 38px;
  line-height: 80px;
  width: 80px;
  height: 80px;
  border-radius: 50px;
  border-width: 2px;
  border-style: solid;
  border-color: #fff;
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, 0px);
  z-index: 2;
  padding: 0px !important;
}

#record ion-icon,
#stop ion-icon,
#continue ion-icon,
#resetButton ion-icon {
  display: block !important;
  margin: auto !important;
  /* centrar */
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

#stop {
  background: #444444;
  cursor: pointer;
  font-size: 32px;
  line-height: 60px;
  width: 60px;
  height: 60px;
  border-radius: 50px;
  border-width: 2px;
  border-style: solid;
  border-color: #fff;
  position: fixed;
  bottom: 30px;
  right: 10%;
  z-index: 2;
  padding: 0px !important;
}

#resetButton {
  background: #444444;
  cursor: pointer;
  font-size: 32px;
  line-height: 60px;
  width: 60px;
  height: 60px;
  border-radius: 50px;
  border-width: 2px;
  border-style: solid;
  border-color: #fff;
  position: fixed;
  bottom: 30px;
  left: 10%;
  z-index: 2;
  padding: 0px !important;
}

#continueButton {
  background: #444444;
  cursor: pointer;
  font-size: 32px;
  line-height: 60px;
  width: 60px;
  height: 60px;
  border-radius: 50px;
  border-width: 2px;
  border-style: solid;
  border-color: #fff;
  position: fixed;
  bottom: 30px;
  right: 10%;
  z-index: 2;
  padding: 0px !important;
}

#programar {
  background: #444444;
  cursor: pointer;
  font-size: 32px;
  line-height: 60px;
  width: 60px;
  height: 60px;
  border-radius: 50px;
  border-width: 2px;
  border-style: solid;
  border-color: #fff;
  position: fixed;
  bottom: 30px;
  right: 10%;
  z-index: 2;
  padding: 0px !important;
}

#record span,
#stop span,
#resetButton span,
#continueButton span,
#programar span {
  display: none;
  font-size: 12px;
  position: absolute;
  top: -26px;
  left: 50%;
  width: 150%;
  height: 12px;
  line-height: 12px;
  transform: translateX(-50%);
  color: #fff;

}

#record:hover span,
#record:focus span,
#stop:hover span,
#stop:focus span,
#resetButton:hover span,
#resetButton:focus span,
#continueButton:hover span,
#continueButton:focus span,
#programar:hover span,
#programar:focus span {
  display: block;
}

.recording .recording_animation,
.stop .recording_animation {
  content: '';
  display: inline-block;
  left: 50%;
  border-radius: 50%;
  position: fixed;
  bottom: calc(30px + 40px);
  z-index: 4;
  width: 200px;
  height: 200px;
  margin: -100px;
}

.recording .recording_animation::before {
  content: '';
  display: inline-block;
  left: 50%;
  width: 0px;
  height: 0px;
  margin: 0px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  -webkit-animation: recording_before 1.5s infinite ease-in-out;
  animation: recording_before 1.5s infinite ease-in-out;
  position: fixed;
  bottom: calc(30px + 40px);
  z-index: 2;
}


.stop #soundClips {
  display: block;
}

.procesando_audio #soundClips,
#soundClips {
  display: none;
}

.col_g {
  text-align: center;
}

.col_g.col_g1 {
  background: transparent;
}

.col_g.col_g2 {
  background: transparent;
}

.col_g.col_g3 {
  background: transparent;
}



/* logout button centrado hasta abajo */
.logoutButtonCabina {
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  color: #fff;
}

.new_main {
  width: 96%;
  max-width: 900px;
  margin: auto;
  position: relative;
}

.nuevaVersion {
  width: 60%;
  margin: auto;
  text-align: center;
  padding-top: 25px;
  float: left;
}

#instrucciones {
  width: 40%;
  margin: auto;
  float: left;
  text-align: justify;
  font-size: 12px;
  padding: 15px 10px 10px 10px;

}

#instrucciones h3 {
  margin-top: 0px;
}

#instrucciones p {
  margin-top: 0px;

}

#instrucciones p span {
  border-radius: 3px;
  border-style: solid;
  border-width: 0px;
  border-color: #777;
  padding: 1px 2px;
  background: #181818;
  font-weight: 500;
  display: inline-block;
}

#instrucciones p span ion-icon {
  margin-bottom: -2px;
}

.soundwave {
  width: 100%;
  display: none !important;
}

.recording .soundwave {
  display: block !important;
}

.stop #record,
.recording #record {
  opacity: 0.3;
}

.exito {
  display: none !important;
  position: fixed;
  z-index: 20;
  border: solid #111 0px;
  text-align: center;
  max-width: 400px;
  max-height: 400px;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 25vh;
  height: 90%;
  width: 90%;
}

.si_inst .exito {
  top: 45vh;
}

.exito p {
  margin: auto;
  display: block;
  top: 45%;
  position: absolute;
  left: 0;
  transform: translate(-50%, -50%);
  font-size: 26px;
  width: 100%;
  font-weight: bold;
  -webkit-animation: slideInText 0.8s 1.3s both ease;
  animation: slideInText 0.8s 1.3s both ease;
  text-align: center;
  padding: 0px 12%;
  text-shadow: 0px 0px 15px black;
}

.programmed .exito {
  display: block !important;
}

.lista_canales {
  padding: 10px;

}


/*
<button class="top_button ver_instrucciones" @click="this.new_main_grabar = 'new_main_instrucciones'">Instrucciones</button>
<button class="top_button ir_a_grabar" @click="this.new_main_grabar = 'new_main_grabar'">Ir a Grabar</button>
*/
@media only screen and (min-width: 767px) {
  .top_button {
    display: none;
  }

  #record {
    position: inherit;
    display: block;
    margin: auto;
    transform: translate(0px, 0px);
  }

  #stop {
    position: inherit;
    display: block;
    margin: auto;
    transform: translate(0px, 0px);
  }

  #resetButton {
    position: inherit;
    display: block;
    margin: auto;
    transform: translate(0px, 0px);
  }

  #continueButton {
    position: inherit;
    display: block;
    margin: auto;
    transform: translate(0px, 0px);
  }

  #programar {
    position: inherit;
    display: block;
    margin: auto;
    transform: translate(0px, 0px);
  }

  .recording .recording_animation,
  .stop .recording_animation {
    left: 50%;
    position: absolute;
    bottom: 50%;
    z-index: 2;
    width: 125px;
    height: 120px;
    margin: -60px;
  }

  .recording .recording_animation::before {
    left: 50%;
    -webkit-animation: recording_before 1.5s infinite ease-in-out;
    animation: recording_before 1.5s infinite ease-in-out;
    position: absolute;
    bottom: 50%;
  }

  .col_g.col_g2 {
    position: relative;
  }

  .top_button2 {
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 5px 7px;
    border-radius: 4px;
    margin-top: 5px;
  }

  .top_button2 ion-icon {
    margin-right: 3px;
    margin-bottom: -2px;
  }

  .no_inst #instrucciones {
    display: none;
  }

  .si_inst #instrucciones {
    display: block;
  }

  .no_inst .nuevaVersion {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .lista_canales {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .exito {
    top: 45vh;
  }

  .top_button2 {
    display: none;
  }

  .new_main {
    width: 600px;
    max-width: 94%;
  }

  .new_main .nuevaVersion {
    display: none;
    width: 100%;
    margin: auto;
  }

  .new_main #instrucciones {
    display: block;
    width: 100%;
    margin: auto;
    text-align: justify;
  }

  .new_main_grabar .nuevaVersion {
    display: block;
  }

  .new_main_grabar #instrucciones {
    display: none;
  }

  .ver_instrucciones {
    color: #fff !important;
    background: #444444 !important;
  }

  .ver_instrucciones,
  .ir_a_grabar {
    color: #fff !important;
    background: #444444 !important;
  }

  .new_main_instrucciones .ir_a_grabar {
    display: block !important;
  }

  .new_main_instrucciones .ver_instrucciones {
    display: none !important;
  }

  .new_main_grabar .ver_instrucciones {
    display: block !important;
  }

  .new_main_grabar .ir_a_grabar {
    display: none !important;
  }

  .top_button {
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 5px 7px;
    border-radius: 4px;
    margin: 15px 10px 0 0;
  }

  .new_main {
    position: relative;
  }

  .lista_canales {
    margin-bottom: 200px;
  }
}
























































@keyframes recording_before {
  80% {
    width: 300px;
    height: 300px;
    margin: -150px;
    opacity: 0;
  }

  to {
    opacity: 0;
  }
}







.fireworks-wrapper {
  width: 15rem;
  height: 15rem;
  z-index: 5;
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.paused svg path {
  -webkit-animation: none !important;
  animation: none !important;
  stroke-dashoffset: 0 !important;
}

.firework {
  width: 1rem;
  position: absolute;
}

.firework.yellow svg path {
  stroke: #ffc125;
}

.firework.yellow:nth-child(1) {
  -webkit-transform: rotate(-45deg) translateY(-4rem);
  transform: rotate(-45deg) translateY(-4rem);
}

.firework.yellow:nth-child(2) {
  -webkit-transform: rotate(-95deg) translateX(-4rem) translateY(-4rem);
  transform: rotate(-95deg) translateX(-4rem) translateY(-4rem);
}

.firework.yellow:nth-child(2) svg path {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.firework.cyan {
  -webkit-transform: rotate(-100deg) translateX(-8rem) translateY(-5rem);
  transform: rotate(-100deg) translateX(-8rem) translateY(-5rem);
}

.firework.cyan svg path {
  stroke: #6cf0f2;
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.firework.left {
  left: 0;
}

.firework.right {
  right: 0;
}

.firework.right.white {
  -webkit-transform: rotate(45deg) translateY(-2rem) translateX(-5.5rem);
  transform: rotate(45deg) translateY(-2rem) translateX(-5.5rem);
}

.firework.right.white svg path {
  stroke: #fff;
}

.firework.right.cyan {
  -webkit-transform: rotate(45deg) translateY(-4rem);
  transform: rotate(45deg) translateY(-4rem);
}

.firework.right.yellow {
  -webkit-transform: rotate(115deg) translateY(-5rem) translateX(7rem);
  transform: rotate(115deg) translateY(-5rem) translateX(7rem);
}

.firework.figure {
  opacity: 0;
  -webkit-animation: hex 0.5s 1.5s forwards cubic-bezier(0.165, 0.84, 0.44, 1);
  animation: hex 0.5s 1.5s forwards cubic-bezier(0.165, 0.84, 0.44, 1);
}

.firework.figure svg {
  -webkit-animation: none;
  animation: none;
}

.firework.figure.hex {
  -webkit-animation-name: hex;
  animation-name: hex;
}

.firework.figure.square {
  -webkit-animation-name: square;
  animation-name: square;
  bottom: 0;
}

.firework.figure.triangle {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
  -webkit-animation-name: triangle;
  animation-name: triangle;
}

.firework.figure.plus {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
  -webkit-animation-name: plus;
  animation-name: plus;
}

.firework.wave svg {
  -webkit-animation: translateWaveWrapper 1.7s 0.9s forwards ease;
  animation: translateWaveWrapper 1.7s 0.9s forwards ease;
}

.firework.wave svg path {
  stroke-dasharray: 170;
  stroke-dashoffset: 170;
  -webkit-animation: dashOffset 1.7s 0.5s forwards ease;
  animation: dashOffset 1.7s 0.5s forwards ease;
}

.firework.wave.large svg path {
  -webkit-animation: dashOffsetLarge 1.7s 0.5s forwards ease;
  animation: dashOffsetLarge 1.7s 0.5s forwards ease;
  stroke-dasharray: 335;
  stroke-dashoffset: 335;
}

.firework.line {
  width: 9rem;
  overflow: hidden;
  opacity: 0;
  -webkit-clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
  clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
  -webkit-animation: dottedLineClip 1s 1.3s forwards ease;
  animation: dottedLineClip 1s 1.3s forwards ease;
}

.firework.line svg {
  -webkit-transform-origin: 400% 400%;
  transform-origin: 400% 400%;
  -webkit-animation: dottedLineOutro 2s 1.6s forwards ease;
  animation: dottedLineOutro 2s 1.6s forwards ease;
}

.firework.line.top {
  top: -8rem;
  right: -5rem;
}

.firework.line.left {
  top: 0;
  bottom: 0;
  left: -10rem;
  -webkit-transform: rotateY(180deg) rotateZ(35deg);
  transform: rotateY(180deg) rotateZ(35deg);
}

.firework.line.bottom {
  top: 130%;
  left: 0;
  right: 0;
  margin: auto;
  -webkit-transform: rotateY(180deg) rotateX(180deg) rotateZ(-35deg);
  transform: rotateY(180deg) rotateX(180deg) rotateZ(-35deg);
}

@-webkit-keyframes hex {
  0% {
    opacity: 1;
    -webkit-transform: translateX(8rem) translateY(0) scale(2) rotateZ(45deg);
    transform: translateX(8rem) translateY(0) scale(2) rotateZ(45deg);
  }

  99% {
    -webkit-transform: translateX(0) translateY(-10rem) scale(0.8);
    transform: translateX(0) translateY(-10rem) scale(0.8);
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes hex {
  0% {
    opacity: 1;
    -webkit-transform: translateX(8rem) translateY(0) scale(2) rotateZ(45deg);
    transform: translateX(8rem) translateY(0) scale(2) rotateZ(45deg);
  }

  99% {
    -webkit-transform: translateX(0) translateY(-10rem) scale(0.8);
    transform: translateX(0) translateY(-10rem) scale(0.8);
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes square {
  0% {
    opacity: 1;
    -webkit-transform: translateX(-1rem) translateY(1rem) scale(2) rotateZ(45deg);
    transform: translateX(-1rem) translateY(1rem) scale(2) rotateZ(45deg);
  }

  99% {
    -webkit-transform: translateX(-5rem) translateY(4rem) scale(0.8);
    transform: translateX(-5rem) translateY(4rem) scale(0.8);
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes square {
  0% {
    opacity: 1;
    -webkit-transform: translateX(-1rem) translateY(1rem) scale(2) rotateZ(45deg);
    transform: translateX(-1rem) translateY(1rem) scale(2) rotateZ(45deg);
  }

  99% {
    -webkit-transform: translateX(-5rem) translateY(4rem) scale(0.8);
    transform: translateX(-5rem) translateY(4rem) scale(0.8);
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes triangle {
  0% {
    opacity: 1;
    -webkit-transform: translateX(2rem) translateY(0) scale(2) rotateZ(45deg);
    transform: translateX(2rem) translateY(0) scale(2) rotateZ(45deg);
  }

  99% {
    -webkit-transform: translateX(8rem) translateY(-4rem) scale(0.8);
    transform: translateX(8rem) translateY(-4rem) scale(0.8);
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes triangle {
  0% {
    opacity: 1;
    -webkit-transform: translateX(2rem) translateY(0) scale(2) rotateZ(45deg);
    transform: translateX(2rem) translateY(0) scale(2) rotateZ(45deg);
  }

  99% {
    -webkit-transform: translateX(8rem) translateY(-4rem) scale(0.8);
    transform: translateX(8rem) translateY(-4rem) scale(0.8);
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes plus {
  0% {
    opacity: 1;
    -webkit-transform: translateX(2rem) translateY(8rem) scale(2) rotateZ(45deg);
    transform: translateX(2rem) translateY(8rem) scale(2) rotateZ(45deg);
  }

  99% {
    -webkit-transform: translateX(8rem) translateY(4rem) scale(0.8);
    transform: translateX(8rem) translateY(4rem) scale(0.8);
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes plus {
  0% {
    opacity: 1;
    -webkit-transform: translateX(2rem) translateY(8rem) scale(2) rotateZ(45deg);
    transform: translateX(2rem) translateY(8rem) scale(2) rotateZ(45deg);
  }

  99% {
    -webkit-transform: translateX(8rem) translateY(4rem) scale(0.8);
    transform: translateX(8rem) translateY(4rem) scale(0.8);
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.close-btn-wrapper {
  top: auto;
  bottom: 1rem;
  z-index: 9;
  width: 2rem;
  height: 2rem;
  -webkit-animation: closeTranslate 0.5s 4s forwards linear;
  animation: closeTranslate 0.5s 4s forwards linear;
}

@-webkit-keyframes clipReveal {
  from {
    -webkit-clip-path: circle(4vh at 50% 50%);
    clip-path: circle(4vh at 50% 50%);
  }

  to {
    -webkit-clip-path: circle(100% at 50% 50%);
    clip-path: circle(100% at 50% 50%);
  }
}

@keyframes clipReveal {
  from {
    -webkit-clip-path: circle(4vh at 50% 50%);
    clip-path: circle(4vh at 50% 50%);
  }

  to {
    -webkit-clip-path: circle(100% at 50% 50%);
    clip-path: circle(100% at 50% 50%);
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes slideUpLogo {
  from {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    -webkit-transform: translateY(-13.5rem);
    transform: translateY(-13.5rem);
  }
}

@keyframes slideUpLogo {
  from {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    -webkit-transform: translateY(-13.5rem);
    transform: translateY(-13.5rem);
  }
}

@-webkit-keyframes waveReveal {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes waveReveal {
  to {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes sbExpandY {
  to {
    height: 100%;
  }
}

@keyframes sbExpandY {
  to {
    height: 100%;
  }
}

@-webkit-keyframes sbExpandX {
  to {
    width: 100%;
  }
}

@keyframes sbExpandX {
  to {
    width: 100%;
  }
}

@-webkit-keyframes sbOpacity {
  to {
    opacity: 1;
  }
}

@keyframes sbOpacity {
  to {
    opacity: 1;
  }
}

@-webkit-keyframes slideInText {
  from {
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
    opacity: 0;
  }

  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInText {
  from {
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
    opacity: 0;
  }

  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes dottedLineOutro {
  to {
    -webkit-transform: rotateZ(45deg);
    transform: rotateZ(45deg);
  }
}

@keyframes dottedLineOutro {
  to {
    -webkit-transform: rotateZ(45deg);
    transform: rotateZ(45deg);
  }
}

@-webkit-keyframes dottedLineClip {
  1% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

@keyframes dottedLineClip {
  1% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

@-webkit-keyframes translateWaveWrapper {
  from {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    -webkit-transform: translateY(-4rem);
    transform: translateY(-4rem);
  }
}

@keyframes translateWaveWrapper {
  from {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    -webkit-transform: translateY(-4rem);
    transform: translateY(-4rem);
  }
}

@-webkit-keyframes dashOffset {
  50% {
    stroke-dashoffset: 170;
  }

  100% {
    stroke-dashoffset: -170;
  }
}

@keyframes dashOffset {
  50% {
    stroke-dashoffset: 170;
  }

  100% {
    stroke-dashoffset: -170;
  }
}

@-webkit-keyframes dashOffsetLarge {
  50% {
    stroke-dashoffset: 335;
  }

  100% {
    stroke-dashoffset: -335;
  }
}

@keyframes dashOffsetLarge {
  50% {
    stroke-dashoffset: 335;
  }

  100% {
    stroke-dashoffset: -335;
  }
}
</style>